import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";
import { Images } from "../constants/Images";
import { toast } from "react-toastify";
import { googleAuth } from "../utils/api";
import { useGoogleLogin } from "@react-oauth/google";

interface Credentials {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [credentials, setCredentials] = useState<Credentials>({
    email: "",
    password: "",
  });

  // Google login success handler
  const responseGoogle = async (authResult: any) => {
    try {
      if (authResult?.code) {
        const result = await googleAuth(authResult.code);
        const { email, name, image, _id, authSource } = result.data.user; 
        const token = result.data.token;
  
        // Ensure userInfo matches the User type
        const userInfo = { email, name, token, image, _id, authSource };
        localStorage.setItem("user-info", JSON.stringify(userInfo));
  
        setUser(userInfo); 
        navigate("/upgrade-plan");
        toast.success("Google login successful!");
      } else {
        throw new Error("Google login failed");
      }
    } catch (error) {
      toast.error("Error during Google Login");
      console.error("Google Login Error:", error);
    }
  };
  

  const googleLogin = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: () => toast.error("Google login failed"),
    flow: "auth-code",
  });

 
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });
      console.log("Success",response)

      const data = await response.json();

      if (response.ok) {
        //token and user
        const { user } = data;
        localStorage.setItem("user-info", JSON.stringify(user));
        setUser(user);

        toast.success("Login successful!");
        navigate("/upgrade-plan");
      } else {
        toast.error(data.message || "Login failed");
      }
    } catch (error) {
      toast.error("An error occurred during login");
      console.error("Login error:", error);
    }
  };

  // Navigation handlers
  const navigateToRegister = () => navigate("/register");
  const navigateToResetPassword = () => navigate("/reset");

  return (
    <div className="bg-1 absolute w-full h-screen flex items-center justify-center">
      <div className="bg-white w-1/4 p-6 shadow-md rounded-xl">
        {/* Logo */}
        <div className="mb-6">
          <img
            className="h-10 w-[150px]"
            src={Images.zintellixLogo}
            alt="Zintellix Logo"
          />
        </div>

        {/* Title */}
        <div className="mb-6">
          <h3 className="text-xl font-medium">Log In</h3>
          <p className="text-sm text-gray-600">to continue to Zintellix</p>
        </div>

        {/* Google Login */}
        <div className="mb-6">
          <button
            onClick={googleLogin}
            className="flex items-center justify-center w-full border-2 border-gray-700 py-2 rounded-full cursor-pointer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1024px-Google_%22G%22_logo.svg.png"
              className="w-6 h-6 mr-3"
              alt="Google Logo"
            />
            Continue with Google
          </button>
        </div>

        {/* Divider */}
        <div className="flex items-center mb-6">
          <hr className="w-1/2 border-gray-300" />
          <span className="mx-2 text-gray-500">OR</span>
          <hr className="w-1/2 border-gray-300" />
        </div>

        {/* Email/Password Login */}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm">
            <div className="relative mb-4">
              <label htmlFor="email" className="block mb-2 font-medium">
                Email
              </label>
              <input
                type="email"
                required
                className="appearance-none block w-full px-4 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Email address"
                value={credentials.email}
                onChange={(e) =>
                  setCredentials({ ...credentials, email: e.target.value })
                }
              />
            </div>
            <div className="relative mb-4">
              <label htmlFor="password" className="block mb-2 font-medium">
                Password
              </label>
              <input
                type="password"
                required
                className="appearance-none block w-full px-4 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Password"
                value={credentials.password}
                onChange={(e) =>
                  setCredentials({ ...credentials, password: e.target.value })
                }
              />
            </div>
          </div>

          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Sign in
          </button>
        </form>

        {/* Footer */}
        <div className="mt-4 text-center">
          <p className="text-sm text-gray-600">
            Don't have an account?{" "}
            <button
              type="button"
              onClick={navigateToRegister}
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Register
            </button>
          </p>
          <button
            onClick={navigateToResetPassword}
            className="text-blue-600 hover:underline"
          >
            Forgot password?
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;