import React from "react";
import FeatureComponent from "./FeatureComponent";
import { features } from "../constants/FeatureAianalyst";

// Define the interface for the feature data
interface FeatureData {
  id: number;
  headings: string[];
  texts: string[];
  img: string;
  logos: string[];
  featureType: string;
}

const AiPricingItem = () => {
  const FeatureAipricing: FeatureData[] = features.FeatureAipricing;

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Section Title */}
      <div className="text-center mb-10">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-800">
          Features
        </h2>
      </div>

      {/* Feature Items */}
      {FeatureAipricing.map((data, ind) => {
        const isEven = ind % 2 === 0;

        return (
          <div
            key={data.id}
            className={`flex flex-col ${
              isEven ? "sm:flex-row" : "sm:flex-row-reverse"
            } items-center gap-8 mb-16 p-6 ${
              isEven ? "bg-blue-50" : "bg-white"
            }`}
          >
            {/* Image Container */}
            <div className="w-full sm:w-1/2 flex justify-center">
              <img
                src={data.img}
                alt={data.headings[0] || "Feature illustration"}
                className="max-w-full h-auto object-contain rounded-lg shadow-md w-[250px] sm:w-[350px] md:w-[420px]"
              />
            </div>

            {/* Feature Component Container */}
            <div className="w-full sm:w-1/2">
              <FeatureComponent feature={data} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AiPricingItem;