import { whyUsContent } from "../constants/WhyUs";
import { useNavigate } from "react-router-dom";
import React from "react";

interface whyUsProps {
  WhyUs: whyUsContent[];
}

const WhyUsItem: React.FC<whyUsProps> = ({ WhyUs }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/demo");
  };

  return (
    <div className="flex flex-col whyUs max-w-7xl mx-auto pl-10 sm:px-6 lg:px-8 py-6">
      <div className="why mb-8 sm:mb-12">
        <div className="text-2xl sm:text-3xl lg:text-4xl">
          Why <span className="us">Us?</span>
        </div>
      </div>

      {WhyUs.map((data, ind) => {
        const isEven = ind % 2 === 0;
        return (
          <div
            key={data.id}
            className={`banner flex flex-col ${
              isEven ? "sm:flex-row" : "sm:flex-row-reverse"
            } 
            justify-between items-center text-left mb-12 sm:mb-16`}
          >
            <div className="w-full lg:mt-14 lg:h-[60vh] sm:w-1/2 mb-6 sm:mb-0 sm:px-4">
              <img
                className="why-img max-w-full h-full object-contain mx-auto lg:w-[180%] lg:h-[80%]"
                src={data.img}
                alt={`${data.heading} illustration`}
              />
            </div>

            <div className="w-full sm:w-1/2 flex flex-col items-start sm:px-4">
              <div className="upper-down flex flex-col items-start text-left">
                <div className="flex justify-between items-center gap-3">
                  <img
                    className="why-logo my-2 mb-2 w-10 h-10 sm:w-12 sm:h-12"
                    src={data.logo}
                    alt={`${data.heading} logo`}
                  />
                  <div className="why-heading grotesk text-xl sm:text-2xl lg:text-3xl">
                    {data.heading}
                  </div>
                </div>

                <div className="w-1/2 onest text-base sm:text-lg lg:text-xl mb-6">
                  {data.text}
                </div>
              </div>
              <button
                className="demo btn-design px-4 py-2 sm:px-6 sm:py-3 text-sm sm:text-base"
                onClick={handleClick}
              >
                Book A Demo
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WhyUsItem;
