import React from "react";
import FeatureComponent from "./FeatureComponent";
import { features } from "../constants/FeatureAianalyst";

const AiAnalystItem = () => {
  const FeatureAianalyst = features.FeatureAianalyst;

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Section Title */}
      <div className="text-center mb-10">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-800">
          Our Features
        </h2>
      </div>

      {/* Feature Items */}
      {FeatureAianalyst.map((data, ind) => {
        const isEven = ind % 2 === 0;
        
        return (
          <div
            key={data.id}
            className={`flex flex-col ${
              isEven ? 'sm:flex-row-reverse' : 'sm:flex-row'
            } items-center gap-8 mb-16 p-6
            ${isEven ? 'bg-blue-50' : 'bg-white'}`}
          >
            {/* Image Container */}
            <div className="w-full sm:w-1/2 flex justify-center">
              <img
                src={data.img}
                alt={data.alt}
                className="max-w-full h-auto object-contain 
                rounded-lg shadow-md 
                w-[250px] sm:w-[350px] md:w-[420px]"
              />
            </div>

            {/* Feature Component Container */}
            <div className="w-full sm:w-1/2">
              <FeatureComponent feature={data} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AiAnalystItem;