import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../utils/style.css";
import Testinomials from "../components/Testinomials";
import parseStyles from "../utils/ParsingFunction";

import {
  categories,
  Category,
  companies,
  Company,
} from "../constants/UseCases";

const UseCases: React.FC = () => {
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleCategoryChange = (categoryId: number) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(categoryId)
        ? prevSelected.filter((id) => id !== categoryId)
        : [...prevSelected, categoryId]
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filterCompanies = (): Company[] => {
    let filteredCompanies = companies;
    if (selectedCategories.length > 0) {
      const selectedCompanyIds = selectedCategories.flatMap(
        (categoryId) =>
          categories.find((cat) => cat.id === categoryId)?.companyIds || []
      );
      filteredCompanies = filteredCompanies.filter((company) =>
        selectedCompanyIds.includes(company.id)
      );
    }

    if (searchQuery) {
      filteredCompanies = filteredCompanies.filter((company) =>
        company.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return filteredCompanies;
  };

  const filteredCompanies = filterCompanies();

  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <Header backgroundStyle="background: linear-gradient(96.3deg, #FEF3ED 0%, #E8F4FF 100.39%)" />

      {/* Banner Section */}
      <div className="about-banner flex flex-col items-center justify-center text-center px-4 py-8 sm:py-12 md:h-[50vh]">
        <h1 className="heading text-2xl sm:text-3xl md:text-4xl font-bold mt-5">
          Ways to use <span className="us">Zintellix</span>
        </h1>
        <p className="text-base sm:text-lg text-[#52606D] onest my-3 max-w-xl">
          Empower every team member with good data analysis and insights
        </p>
        <button className="demo btn-design my-6 px-6 py-2 text-sm sm:text-base">
          Book a Demo
        </button>
      </div>

      {/* How Professionals Use Section */}
      <div className="use m-4 sm:m-10 text-center">
        <h2 className="text-xl sm:text-2xl md:text-3xl grotesk font-semibold">
          How Professionals Use Zintellix?
        </h2>
      </div>

      {/* Categories Section */}
      <div className="categories m-4 sm:m-10">
        <ul className="flex flex-wrap justify-center gap-4 sm:gap-6 sm:justify-center">
          <div className="flex w-full sm:hidden">
            {/* Left Side (First Half) */}
            <div className="w-1/2 flex flex-col items-start gap-4">
              {categories
                .slice(0, Math.floor(categories.length / 2))
                .map((category: Category) => (
                  <li key={category.id} className="flex items-center">
                    <div className="bg-white rounded-full p-2 sm:p-3 shadow-sm">
                      <span
                        className={`cursor-pointer text-sm sm:text-base ${
                          selectedCategories.includes(category.id)
                            ? "font-semibold text-black"
                            : "text-[#52606D]"
                        }`}
                        onClick={() => handleCategoryChange(category.id)}
                      >
                        {category.name}
                      </span>
                    </div>
                  </li>
                ))}
            </div>
            {/* Right Side (Second Half) */}
            <div className="w-1/2 flex flex-col items-end gap-4">
              {categories
                .slice(Math.floor(categories.length / 2))
                .map((category: Category) => (
                  <li key={category.id} className="flex items-center">
                    <div className="bg-white rounded-full p-2 sm:p-3 shadow-sm">
                      <span
                        className={`cursor-pointer text-sm sm:text-base ${
                          selectedCategories.includes(category.id)
                            ? "font-semibold text-black"
                            : "text-[#52606D]"
                        }`}
                        onClick={() => handleCategoryChange(category.id)}
                      >
                        {category.name}
                      </span>
                    </div>
                  </li>
                ))}
            </div>
          </div>
          {/* Larger Screens (Original Centered Layout) */}
          <div className="hidden sm:flex sm:flex-wrap sm:gap-6 sm:justify-center">
            {categories.map((category: Category) => (
              <li key={category.id} className="flex items-center">
                <div className="bg-white rounded-full p-2 sm:p-3 shadow-sm">
                  <span
                    className={`cursor-pointer text-sm sm:text-base ${
                      selectedCategories.includes(category.id)
                        ? "font-semibold text-black"
                        : "text-[#52606D]"
                    }`}
                    onClick={() => handleCategoryChange(category.id)}
                  >
                    {category.name}
                  </span>
                </div>
              </li>
            ))}
          </div>
        </ul>
      </div>

      {/* Search Input */}
      <div className="m-4 sm:m-10 flex justify-center">
        <input
          type="text"
          placeholder="Search companies..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input w-full max-w-xs sm:max-w-md p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm sm:text-base"
        />
      </div>

      {/* Companies Grid */}
      <div className="flex-1 p-4 sm:p-6 m-4 sm:m-10 overflow-y-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 md:gap-12">
          {filteredCompanies.map((company: Company) => {
            const styles = parseStyles(company.bgStyle);
            return (
              <div
                key={company.id}
                style={styles}
                className="group bg-white flex flex-col sm:flex-row border h-auto sm:h-[30vh] border-gray-300 rounded-2xl p-4 shadow transition-transform transform hover:scale-105 overflow-hidden"
              >
                <div className="flex flex-col sm:flex-row w-full">
                  <div className="l w-full sm:w-2/3">
                    <h3 className="text-[#2F269D] grotesk text-lg sm:text-xl pl-0 sm:pl-4 m-3">
                      {company.name}
                    </h3>
                    <p className="text-[#52606D] onest text-sm sm:text-base pl-0 sm:pl-4 my-4 mx-3">
                      {company.description}
                    </p>
                  </div>
                  <div className="r w-full sm:w-1/3 flex justify-center items-center">
                    <img
                      src={company.imageUrl}
                      alt={company.name}
                      className="w-16 sm:w-20 h-16 sm:h-20 object-contain m-auto"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Testinomials and Footer - Unchanged */}
      <Testinomials />
      <Footer />
    </div>
  );
};

export default UseCases;
