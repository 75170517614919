import React, { useState } from "react";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import Form4 from "./Form4";
import Form5 from "./Form5";
import Buffering from "./Buffering";
import useNavigation from "../utils/useNavigation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import {useUser} from "../UserContext";

const Onboard: React.FC = () => {
  const Navigate = useNavigate();
  const { navIndex, navHandler } = useNavigation();
  const [isBuffering, setIsBuffering] = useState(false);
  // const {user} = useUser();

  // let token = localStorage.getItem("authToken");
  // if (!token) {
  //   token =
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjY2NzJiOTdhMjJhMWNjOTA1MmRhZTZhIn0sImlhdCI6MTcyMjQ1NTM0Nn0.AhZAKrj2d9z8pfoBOLG4oJc4FPkEyrmGWY1Gy_zReBs";
  // }

  const [formData, setFormData] = useState({
    companyName: "",
    type: "",
    companySize: "",
    workspaceName: "",
    role: "",
    selectedCompany: "",
    email1: "",
    email2: "",
    email3: "",
  });

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRoleSelect = (role: string) => {
    setFormData({
      ...formData,
      role,
    });
  };

  const handleCompanySelect = (selectedCompany: string) => {
    setFormData({
      ...formData,
      selectedCompany,
    });
  };

  // this function restricts user to move forward without filling all the required details
  const handleContinue = () => {
    if (
      navIndex === 0 &&
      (!formData.companyName || !formData.type || !formData.companySize)
    ) {
      toast.error("All details are required");
      return;
    }
    // console.log(user);
    if (navIndex === 1 && !formData.workspaceName) {
      toast.error("Workspace name is required");
      return;
    }
    if (navIndex === 2 && !formData.role) {
      toast.error("Role selection is required");
      return;
    }
    if (navIndex === 3 && !formData.selectedCompany) {
      toast.error("Company selection is required");
      return;
    }
    if (navIndex === 4) {
      console.log("All Form Data:", formData);
    }

    if (navIndex === 4) {
      setIsBuffering(true);
      setTimeout(() => {
        saveOnboardingData(formData);
        setIsBuffering(false);
        Navigate("/homwnew");
      }, 5000);
    } else {
      navHandler("continue");
    }
  };

  const handleSkip = () => {
    setFormData({
      ...formData,
      email1: "",
      email2: "",
      email3: "",
    });
    console.log(formData);
    setIsBuffering(true);
    setTimeout(() => {
      saveOnboardingData(formData);
      setIsBuffering(false);
      Navigate("/homenew");
    }, 2000);
    navHandler("continue");
  };

  const saveOnboardingData = async (data: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/onboarding/save`,
        data,
      );
      return response.data;
    } catch (error) {
      toast.error("Error saving onboarding data");
      throw error;
    }
  };

  return (
    <div>
      {isBuffering ? (
        <Buffering />
      ) : (
        <>
          {navIndex === 0 && (
            <Form1
              formData={formData}
              handleFormChange={handleFormChange}
              handleContinue={handleContinue}
            />
          )}
          {navIndex === 1 && (
            <Form2
              formData={formData}
              handleFormChange={handleFormChange}
              handleContinue={handleContinue}
            />
          )}
          {navIndex === 2 && (
            <Form3
              formData={formData}
              handleRoleSelect={handleRoleSelect}
              handleContinue={handleContinue}
            />
          )}
          {navIndex === 3 && (
            <Form4
              formData={formData}
              handleCompanySelect={handleCompanySelect}
              handleContinue={handleContinue}
            />
          )}
          {navIndex === 4 && (
            <Form5
              formData={formData}
              handleFormChange={handleFormChange}
              handleContinue={handleContinue}
              handleSkip={handleSkip}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Onboard;
