import React, { createContext, useContext, useState, useEffect } from 'react';
import { User, UserContextType, UserProviderProps } from './types/user';
import { useAuth } from './hooks/useAuth';

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [state, setState] = useState({
    user: null as User | null,
    isAuthenticated: false,
    isLoading: true
  });

  const { login: authLogin, logout: authLogout, getStoredUser } = useAuth();

  useEffect(() => {
    const initializeAuth = async () => {
      const storedUser = getStoredUser();
      if (storedUser) {
        setState({
          user: storedUser,
          isAuthenticated: true,
          isLoading: false
        });
      } else {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    };

    initializeAuth();
  }, [getStoredUser]);

  const login = async (user: User) => {
    const success = await authLogin(user);
    if (success) {
      setState({
        user,
        isAuthenticated: true,
        isLoading: false
      });
    }
  };

  const logout = async () => {
    const success = await authLogout();
    if (success) {
      setState({
        user: null,
        isAuthenticated: false,
        isLoading: false
      });
    }
  };

  const setUser = (user: User | null) => {
    setState(prev => ({
      ...prev,
      user,
      isAuthenticated: !!user
    }));
  };

  const value: UserContextType = {
    ...state,
    setUser,
    login,
    logout
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};