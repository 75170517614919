import "./App.css";
import AiAnalystPage from "./pages/AiAnalystPage";
import Homepage from "./pages/Homepage";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AiPricingPage from "./pages/AiPricingPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import TandC from "./pages/TnC";
import Login from "./components/Login";
import Registration from "./components/Registration";
import About from "./pages/About";
import Integrations from "./pages/Integrations";
import PasswordReset from "./components/PasswordReset";
import NewPassword from "./components/NewPassword";
import BlogList from "./components/BlogList";
import BlogDetail from "./components/BlogDetail";
import UseCases from "./pages/UseCases";
import DemoPage from "./pages/DemoPage";
import Home from "./onboarding/Home";
import { ToastContainer } from "react-toastify";
import Onboard from "./onboarding/Onboard";
import DataConnector from "./workspace/components/DataConnector";
import Homenew from "./workspace/components/Home";
import Analytics from "./workspace/components/Analytics";
import ZinAI from "./workspace/components/ZinAI";
import Profile from "./workspace/components/settings/Profile";
import Plans from "./workspace/components/settings/Plans";
import AboutSettings from "./workspace/components/AboutSetction";
import Notifications from "./workspace/components/settings/NotificationPreferences";
import WorkspaceLayout from "./workspace/components/WorkspaceLayout";
import TeamPage from "./workspace/components/settings/Teams";
import WorkspaceSettings from "./workspace/components/settings/WorkspaceSettings";
import Dashboards from "./workspace/components/Dashboards";
import UpgradePlan from "./workspace/components/UpgradePlan";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/demo" element={<DemoPage />} />
        <Route path="/aianalyst" element={<AiAnalystPage />} />
        <Route path="/aiprice" element={<AiPricingPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/cookie" element={<CookiePolicy />} />
        <Route path="/tnc" element={<TandC />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/about" element={<About />} />
        <Route path="/usage" element={<UseCases />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="/reset" element={<PasswordReset />} />
        <Route path="/reset-pass" element={<NewPassword />} />
        <Route path="/blogs" element={<BlogList />} />
        <Route path="/blogs/:id" element={<BlogDetail />} />
        <Route path="/onboard" element={<Home />} />
        <Route path="/homenew" element={<Homenew />} />
        <Route path="/onboard-page" element={<Onboard />} />
        <Route path="upgrade-plan" element={<UpgradePlan />} />

        {/* workspace */}

        <Route path="/workspace" element={<WorkspaceLayout />}>
          <Route path="analytics" element={<Analytics />} />
          <Route path="dashboard" element={<Dashboards />} />
          <Route path="zin-ai" element={<ZinAI />} />
          <Route path="data-connector" element={<DataConnector />} />
          <Route
            path="settings"
            element={<Navigate to="/workspace/settings/profile" replace />}
          />
          <Route path="settings">
            <Route index element={<Profile />} />
            <Route path="profile" element={<Profile />} />
            <Route path="plans" element={<Plans />} />
            <Route path="about" element={<AboutSettings />} />
            <Route path="workspace" element={<WorkspaceSettings />} />
            <Route
              path="notifications"
              element={<Notifications role="admin" />}
            />
            <Route path="teams" element={<TeamPage />} />
          </Route>
        </Route>

        {/* Settings pages */}
        <Route path="/workspace/settings" element={<Profile />}>
          <Route path="profile" element={<Profile />} />
          <Route path="plans" element={<Plans />} />
          <Route path="about-setting" element={<AboutSettings />} />
          <Route path="notifications" element={<Notifications role="user" />} />
        </Route>
        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
