const Signup = () => {
  return (
    <div className="signup w-full min-h-[35vh] bg-gray-900 flex flex-col justify-center items-center text-white py-12 sm:py-16 lg:py-20 px-4 sm:px-6 lg:px-8 mb-8 md:mb-12">
      <h2 className="sign-text text-center text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl mb-6 sm:mb-8 lg:mb-10">
        Know the power of AI in your new Data Analysis platform
      </h2>
      <div className="w-full max-w-md space-y-4 sm:space-y-0 sm:flex sm:items-center sm:gap-4">
        <input
          type="email"
          placeholder="Enter email"
          className="w-full px-4 py-3 text-gray-800 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
          aria-label="Email address"
        />
        <button className="w-full h-11 sm:w-auto px-6 bg-blue-600 hover:bg-blue-700 text-white rounded-lg grotesk font-medium transition-colors duration-200">
          Book a Demo
        </button>
      </div>
    </div>
  );
};

export default Signup;