import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../utils/style.css";
import { toast } from "react-toastify";

const Form: React.FC = () => {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    companySize: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    if (name === "email") {
      const forbiddenDomains = [
        "gmail.com",
        "hotmail.com",
        "yahoo.com",
        "outlook.com",
      ];
      const domain = value.split("@")[1];

      if (domain && forbiddenDomains.includes(domain)) {
        setEmailError("Please use a business email address.");
      } else {
        setEmailError("");
      }
    }

    if (name === "phoneNumber") {
      const phonePattern = /^[0-9\b]+$/;
      if (!value.match(phonePattern)) {
        setPhoneError("Please enter a valid phone number.");
      } else if (value.length > 15) {
        setPhoneError("Phone number cannot exceed 15 digits.");
      } else {
        setPhoneError("");
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (emailError || phoneError) {
      toast.error("Please correct the errors in the form.");
      return;
    }

    setIsSubmitting(true);
    console.log(`${process.env.REACT_APP_BACKEND_URL}/api/book/demo`,"checking");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/book/demo`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      const json = await response.json();

      if (response.ok) {
        toast.success("Demo booked successfully!");
        navigate("/aianalyst");
      } else {
        toast.error(json.error || "Something went wrong!");
      }
    } catch (error) {
      toast.error("Network error! Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-lg mb-5 mx-auto p-6 bg-white shadow-md rounded-md form text-left font-normal">
      <form onSubmit={handleSubmit}>
        <div className="mb-5 mt-3 h-18">
          <label
            className="block text-gray-700 text-sm mb-2 font-normal"
            htmlFor="fullName"
          >
            Full Name
          </label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your Name"
            required
          />
        </div>

        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm mb-2 font-normal"
            htmlFor="companyName"
          >
            Company
          </label>
          <input
            type="text"
            name="companyName"
            id="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Company Name"
            required
          />
        </div>

        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm mb-2 font-normal"
            htmlFor="email"
          >
            E-mail (Business)
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Business Email"
            required
          />
          {emailError && (
            <p className="text-red-500 text-xs italic">{emailError}</p>
          )}
        </div>

        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm mb-2 font-normal"
            htmlFor="phoneNumber"
          >
            Phone Number
          </label>
          <input
            type="tel"
            name="phoneNumber"
            id="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Phone Number"
            required
          />
          {phoneError && (
            <p className="text-red-500 text-xs italic">{phoneError}</p>
          )}
        </div>

        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm mb-2 font-normal"
            htmlFor="companySize"
          >
            Company Size
          </label>
          <select
            name="companySize"
            id="companySize"
            value={formData.companySize}
            onChange={handleChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select Company Size</option>
            <option value="Less than 10">Less than 10</option>
            <option value="10-50">10-50</option>
            <option value="50-100">50-100</option>
            <option value="More than 100">More than 100</option>
          </select>
        </div>

        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm mb-2 font-normal"
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            name="message"
            id="message"
            value={formData.message}
            onChange={handleChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your message..."
            rows={3}
            required
          ></textarea>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none ${
            isSubmitting ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
          }`}
        >
          {isSubmitting ? "Submitting..." : "Book a Demo"}
        </button>
      </form>
    </div>
  );
};

export default Form;
