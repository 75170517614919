import { Working } from "../constants/Working";
import React from "react";

const WorkingItem = () => {
  const working = Working.working;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="heading mb-8 sm:mb-12 lg:mb-16">
        <div className="why text-center">
          <div className="text-2xl sm:text-3xl lg:text-4xl">
            How Zintellix <span className="us">Works?</span>
          </div>
        </div>
      </div>
      
      <div className="why-grid w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8">
          {working.map((data, key) => (
            <div 
              key={key} 
              className="first p-6 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="upper flex items-center text-left mb-4">
                <img 
                  className="how-logo w-10 h-10 sm:w-12 sm:h-12 mr-4" 
                  src={data.logo} 
                  alt={`${data.heading} logo`}
                />
                <div className="how-heading text-lg sm:text-xl lg:text-2xl grotesk">
                  {data.heading}
                </div>
              </div>
              <div className="how-content why-content onest text-left text-base sm:text-lg text-gray-600">
                {data.text}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkingItem;