import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";
import { Navbar } from "../constants/Navbar";
import { Images } from "../constants/Images";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import parseStyles from "../utils/ParsingFunction";

interface NavbarProps {
  backgroundStyle: string;
}

const Header: React.FC<NavbarProps> = (props) => {
  const { backgroundStyle } = props;
  const backgroundStyles = backgroundStyle ? parseStyles(backgroundStyle) : {};
  const { user } = useUser();
  const navigate = useNavigate();
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    navigate("/demo");
  };

  const handleMouseEnter = (id: number) => {
    setHoveredItem(id);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleDropdownClick = (redirectUrl: string) => {
    navigate(redirectUrl);
    closeMobileMenu();
  };

  const handleSignInClick = () => {
    navigate("/login");
    closeMobileMenu();
  };

  const handleItemClick = (redirectUrl?: string | string[]) => {
    if (typeof redirectUrl === "string") {
      navigate(redirectUrl);
      closeMobileMenu();
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    setOpenDropdown(null);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    setOpenDropdown(null);
  };

  const toggleDropdown = (index: number) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current && 
        !menuRef.current.contains(event.target as Node) && 
        mobileMenuOpen
      ) {
        closeMobileMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [mobileMenuOpen]);

  return (
    <div
      className="nav navbar flex flex-row justify-between items-center text-[#52606D] px-4 py-3 w-full max-w-screen-2xl mx-auto"
      style={backgroundStyles}
    >
      <div className="logo flex-shrink-0">
        <Link to="/">
          <img
            className="logo-img h-8 sm:h-9 md:h-10"
            src={Images.zintellixLogo}
            alt="Logo"
          />
        </Link>
      </div>

      {/* Desktop Menu */}
      <ul className="hidden md:flex justify-center space-x-2 lg:space-x-6 flex-grow">
        {Navbar.map((data) => (
          <li
            key={data.id}
            className="relative flex flex-col items-center group text-sm lg:text-base"
            onMouseEnter={() => handleMouseEnter(data.id)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleItemClick(data.redirect)}
          >
            <div className="cursor-pointer flex items-center mb-1 lg:mb-2">
              {data.text}
              {data.logo &&
                (hoveredItem === data.id ? (
                  <KeyboardArrowUpIcon className="ml-1" />
                ) : (
                  <KeyboardArrowDownIcon className="ml-1" />
                ))}
            </div>
            {data.dropdownItems && (
              <div className="dropdown-menu absolute left-0 mt-8 w-64 bg-white rounded-lg border border-gray-200 shadow-lg z-10 hidden group-hover:block">
                <div className="m-4 ml-5 mb-2 text-[#5B5B55] text-sm">
                  {data.text}
                </div>
                <ul>
                  {data.dropdownItems.map((item, index) => (
                    <li
                      key={index}
                      className="mt-2 px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center text-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDropdownClick(data.redirect[index]);
                      }}
                    >
                      <img
                        src={data.drop[index]}
                        alt={item}
                        className="mr-3 h-8 w-8 lg:h-9 lg:w-9"
                      />
                      <div className="flex flex-col">
                        <span>{item}</span>
                        <span
                          className={`${data.style[index]} font-normal text-[#7C7C73] text-xs lg:text-sm`}
                        >
                          {data.subtitle[index]}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
      </ul>

      {/* Mobile Menu Button */}
      <button className="md:hidden flex-shrink-0" onClick={toggleMobileMenu}>
        {mobileMenuOpen ? (
          <CloseIcon sx={{ color: "black", fontSize: "1.75rem" }} />
        ) : (
          <MenuIcon sx={{ fontSize: "1.75rem" }} />
        )}
      </button>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div 
          ref={menuRef}
          className="absolute top-14 left-0 right-0 bg-white shadow-lg z-20 md:hidden"
        >
          <ul className="flex flex-col text-sm">
            {Navbar.map((data, index) => (
              <li key={data.id} className="border-b border-gray-200">
                <div
                  className="flex justify-between items-center p-3 sm:p-4"
                  onClick={() =>
                    data.dropdownItems
                      ? toggleDropdown(index)
                      : handleItemClick(data.redirect)
                  }
                >
                  {data.text}
                  {data.dropdownItems && (
                    openDropdown === index ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  )}
                </div>
                {data.dropdownItems && openDropdown === index && (
                  <ul className="bg-gray-100">
                    {data.dropdownItems.map((item, subIndex) => (
                      <li
                        key={subIndex}
                        className="py-2 px-6 sm:px-8 flex items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDropdownClick(data.redirect[subIndex]);
                        }}
                      >
                        <img
                          src={data.drop[subIndex]}
                          alt={item}
                          className="mr-3 h-6 w-6"
                        />
                        <div className="flex flex-col">
                          <span>{item}</span>
                          <span
                            className={`${data.style[subIndex]} text-xs font-normal text-[#7C7C73]`}
                          >
                            {data.subtitle[subIndex]}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>

          {/* Mobile Login/Demo Buttons */}
          <div className="flex flex-col p-3 sm:p-4 border-t border-gray-200">
            <button
              className="demo btn-design text-sm w-full mb-3 py-2"
              onClick={() => {
                handleClick();
                closeMobileMenu();
              }}
            >
              Talk to founder 
            </button>
            {user ? (
              <div
                className="flex items-center cursor-pointer"
                onClick={handleSignInClick}
              >
                <img
                  src={user.picture || Images.defaultProfile}
                  alt="Profile"
                  className="h-7 w-7 sm:h-8 sm:w-8 rounded-full mr-2"
                />
                <span className="text-sm">Profile</span>
              </div>
            ) : (
              <p
                className="cursor-pointer text-sm"
                onClick={handleSignInClick}
              >
                Sign in
              </p>
            )}
          </div>
        </div>
      )}

      {/* Desktop Login/Demo Buttons */}
      <div className="hidden md:flex items-center space-x-3 lg:space-x-4 flex-shrink-0">
        <button
          className="demo btn-design text-xs lg:text-sm px-3 py-2"
          onClick={handleClick}
        >
          Talk to founder 
        </button>
        {user ? (
          <img
            src={user.picture || Images.defaultProfile}
            alt="Profile"
            className="h-7 w-7 lg:h-8 lg:w-8 rounded-full cursor-pointer"
            onClick={handleSignInClick}
          />
        ) : (
          <p
            className="cursor-pointer text-xs lg:text-sm"
            onClick={handleSignInClick}
          >
            Sign in
          </p>
        )}
      </div>
    </div>
  );
};

export default Header;