
import electroglobal from "../assets/images/carousel_logo/electroglobal.jpeg";
import cc from "../assets/images/carousel_logo/cc.png";
import kalaaiStudio from "../assets/images/carousel_logo/kalaaiStudio.jpg";


export const TrustedBy = [
    {
        "id": 1,
        "alt" : "cc",
        "img" : cc,
        "style" : `
        width : 160px`
    },
    {
        "id": 2,
        "alt" : "electroglobal",
        "img" : electroglobal,
        "style" : `
        width : 96px`
    },
    {
        "id": 3,
        "alt" : "KallaiStudio",
        "img" : kalaaiStudio,
        "style" : `
        width : 96px`
    },
    
];