// BlogList.tsx
import React, { useState } from "react"; // Importing React and useState hook
import { useNavigate } from "react-router-dom"; // Importing useNavigate for navigation
import Header from "./Header"; // Importing Header component
import { Blogs } from "../constants/BlogsData"; // Importing blog data from constants
// import Signup from "./Signup"; // Importing Signup component
import Footer from "./Footer"; // Importing Footer component
import { Images } from "../constants/Images"; // Importing images from constants
import axios from "axios"; // Importing axios for HTTP requests

const BlogList = () => {
  const navigate = useNavigate(); // Initializing navigation
  const [email, setEmail] = useState(""); // State for email input

  // Function to handle blog click navigation
  const handleBlogClick = (id: number) => {
    navigate(`/blogs/${id}`); // Navigating to the blog detail page
  };

  // Function to handle newsletter subscription form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Preventing default form submission

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/newsletter/subscribe`, { email }); // Sending email to the server
      if (response.status === 200) {
        alert("Subscribed successfully!"); // Success message
        setEmail(""); // Clear the input field
      } else {
        alert("Failed to subscribe. Please try again."); // Error message for failed subscription
      }
    } catch (error) {
      console.error("Error subscribing:", error); // Logging error
      alert("An error occurred. Please try again."); // General error message
    }
  };

  const bannerBlog = Blogs[1]; // Selecting the second blog for the banner

  return (
    <div>
      <Header backgroundStyle="background:linear-gradient(96.3deg, #FEF3ED 0%, #E8F4FF 100.39%)" />{" "}
      {/* Header with background style */}
      <div className="about-banner flex mx-auto h-[70vh] justify-between">
        {" "}
        {/* Banner section */}
        <div className="w-2/5 flex flex-col justify-center mx-auto">
          {" "}
          {/* Left content */}
          <div className="onest text-[#52606D] flex justify-between">
            {" "}
            {/* Writer and time */}
            <div>{bannerBlog.writer}</div>
            <div>{bannerBlog.time}</div>
          </div>
          <div className="text-5xl my-3 pl-0 grotesk">{bannerBlog.name}</div>{" "}
          {/* Blog name */}
          <div className="text-xl my-2 onest text-[#52606D]">
            {bannerBlog.description} {/* Blog description */}
          </div>
          <div className="us my-2 text-lg">Read more {">"}</div>{" "}
          {/* Read more link */}
        </div>
        <div className="right items-center">
          {" "}
          {/* Right image section */}
          <img src={Images.blog4} alt="blog4" className="w-4/5 h-3/5" />
        </div>
      </div>
      <div className="container mx-auto py-8">
        {" "}
        {/* Main blog list container */}
        <h1 className="text-4xl grotesk text-center font-bold mb-8">
          Blog Posts
        </h1>{" "}
        {/* Blog posts title */}
        <div className="grid grid-cols-3 gap-6">
          {" "}
          {/* Grid layout for blog posts */}
          {Blogs.map(
            (
              blog // Mapping through blog data
            ) => (
              <div
                key={blog.id} // Unique key for each blog
                className="bg-white flex flex-col border border-gray-300 rounded-lg p-3 hover:shadow-lg" // Blog card styling
              >
                <img
                  src={blog.imageUrl} // Blog image source
                  alt={blog.name} // Alt text for the image
                  className="w-full object-cover rounded-lg" // Image styling
                />
                <div className="content pl-4">
                  {" "}
                  {/* Blog content section */}
                  <div className="onest text-[#52606D] mb-2 flex justify-between">
                    {" "}
                    {/* Writer and time */}
                    <div>{blog.writer}</div>
                    <div>{blog.time}</div>
                  </div>
                  <div className="text-xl font-semibold grotesk">
                    {blog.name}
                  </div>{" "}
                  {/* Blog name */}
                  <div className="text-gray-600 mt-2 onest">
                    {blog.description} {/* Blog description */}
                  </div>
                  <div
                    className="us mt-3 text-lg cursor-pointer" // Read more link styling
                    onClick={() => handleBlogClick(blog.id)} // Click handler for navigation
                  >
                    Read more {">"}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div className="newsletter signup h-[45vh] w-4/5 mx-auto rounded-3xl flex justify-center items-center flex-col text-white mb-[3rem]">
        {" "}
        {/* Newsletter signup section */}
        <div className="items-center grotesk text-[#EDF0F6] text-center text-4xl w-3/5 my-3">
          Subscribe to our newsletter {/* Newsletter title */}
        </div>
        <div className="mb-10 text-[#C3CFE5] text-base onest w-4/5 text-center">
          Stay updated with Zintellix Blog by signing up for our newsletter{" "}
          {/* Newsletter description */}
        </div>
        <div className="email flex items-center justify-center w-3/5 h-[8vh] my-5 mx-auto">
          {" "}
          {/* Email input section */}
          <form
            onSubmit={handleSubmit} // Form submission handler
            className="flex items-center w-full h-full"
          >
            <input
              type="email" // Input type for email
              placeholder="Enter email" // Placeholder text
              value={email} // Controlled input value
              onChange={(e) => setEmail(e.target.value)} // Updating email state on change
              required // Required field
              className="w-3/5 h-[100%] pl-5 bg-[#232f46] input-email" // Input styling
            />
            <button
              type="submit" // Button type for submission
              className="text-[#12213D] bg-white w-1/5 h-[100%] text-base grotesk font-normal" // Button styling
            >
              Join Now {/* Button text */}
            </button>
          </form>
        </div>
      </div>
      <Footer /> {/* Footer component */}
    </div>
  );
};

export default BlogList;
