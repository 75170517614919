import { BannerItemContent } from "../constants/Banner";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import parseStyles from "../utils/ParsingFunction";

interface BannerItemProps {
  content: BannerItemContent[];
  image: string;
  backgroundStyle: string;
  imageStyle: string;
  button: string;
  page: string;
}

const BannerItem: React.FC<BannerItemProps> = (props) => {
  const { content, image, backgroundStyle, imageStyle, button, page } = props;
  const backgroundStyles = backgroundStyle ? parseStyles(backgroundStyle) : {};
  const baseImageStyles = imageStyle ? parseStyles(imageStyle) : {};

  const navigate = useNavigate();
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const textOptions = ["Product Team", "Sales Team", "Marketing Team"];

  useEffect(() => {
    if (page === "home") {
      const interval = setInterval(() => {
        setCurrentTextIndex(
          (prevIndex) => (prevIndex + 1) % textOptions.length
        );
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [textOptions.length, page]);

  const HandleClick = () => {
    navigate("/demo");
  };

  // Responsive image styles for /aianalyst page on mobile
  const responsiveImageStyles = {
    ...baseImageStyles,
    ...(page === "aianalyst" && window.innerWidth < 640
      ? { width: "200px", height: "200px", right: "1rem" }
      : {}),
    right: 0, // Ensures right: 0 is always applied
  };

  return (
    <div
      className="flex flex-col md:flex-row mt-4 mb-4 overflow-hidden justify-center items-center gap-6 md:gap-0"
      style={backgroundStyles}
    >
      <div className="w-full md:w-1/2 banner-left flex flex-col justify-center items-start text-left md:ml-12 lg:ml-24 px-4">
        {content.map((data, key) => {
          const styles = parseStyles(data.style);

          // Handle the home page first item specially
          if (key === 0 && page === "home") {
            return (
              <div key={data.id} className="flex flex-col">
                <div
                  style={{
                    ...styles,
                    fontSize:
                      window.innerWidth < 768 ? "18px" : styles.fontSize,
                    lineHeight:
                      window.innerWidth < 768 ? "22px" : styles.lineHeight,
                  }}
                >
                  E Commerce AI
                </div>
                <div
                  style={{
                    ...styles,
                    fontSize:
                      window.innerWidth < 768 ? "18px" : styles.fontSize,
                    lineHeight:
                      window.innerWidth < 768 ? "22px" : styles.lineHeight,
                  }}
                >
                  Analyst for
                </div>
                <div
                  style={{
                    ...styles,
                    fontSize:
                      window.innerWidth < 768 ? "20px" : styles.fontSize,
                    lineHeight:
                      window.innerWidth < 768 ? "24px" : styles.lineHeight,
                    color: "#2f269d",
                  }}
                  className="transition-all transform-gpu ease-in-out duration-500"
                >
                  {textOptions[currentTextIndex]}
                </div>
              </div>
            );
          }

          // Skip the second item for home page since we're handling it above
          if (key === 1 && page === "home") return null;

          // Render other pages and remaining home page items
          return (
            <div
              key={data.id}
              style={{
                ...styles,
                fontSize:
                  window.innerWidth < 768 && styles.fontSize === "64px"
                    ? "18px"
                    : window.innerWidth < 768 && styles.fontSize === "68px"
                    ? "42px"
                    : styles.fontSize,
                lineHeight:
                  window.innerWidth < 768 && styles.lineHeight === "70.7px"
                    ? "22px"
                    : styles.lineHeight,
              }}
            >
              {data.text}
            </div>
          );
        })}
        <button
          className={`btn-design mt-4 px-4 py-2 sm:px-6 sm:py-3 text-xs sm:text-base md:text-lg`}
          onClick={HandleClick}
        >
          {button}
        </button>
      </div>
      <div className="banner-right w-full md:w-1/2 flex justify-end items-center p-0 overflow-hidden">
        <img
          className="dashboard max-w-full h-auto m-0"
          src={image}
          alt="dashboard"
          style={responsiveImageStyles}
        />
      </div>
    </div>
  );
};

export default BannerItem;