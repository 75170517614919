import { useState } from "react";
import { FAQ } from "../constants/FAQ";
import { Images } from "../constants/Images";

const FAQs = () => {
  const [openId, setOpenId] = useState<number | null>(null);

  const toggleMode = (id: number) => {
    setOpenId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div className="faq-container max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
      <h2 className="text-center text-black text-2xl sm:text-3xl lg:text-4xl font-bold mb-8 sm:mb-12 lg:mb-16 grotesk">
        Frequently Asked Questions
      </h2>
      <div className="accordion space-y-4 sm:space-y-6">
        {FAQ.map((data) => {
          const isOpen = openId === data.id;
          return (
            <div
              key={data.id}
              className="accordion-item bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
              onClick={() => toggleMode(data.id)}
            >
              <label
                htmlFor={`question${data.id}`}
                className="accordion-header flex justify-between items-center text-left cursor-pointer p-4 sm:p-5"
              >
                <span className="header grotesk text-base sm:text-lg lg:text-xl font-medium">
                  {data.prefix} <span className="us text-blue-600">{data.blue}</span>{" "}
                  {data.suffix}
                </span>
                <img
                  className={`w-5 h-5 sm:w-6 sm:h-6 transition-transform duration-300 ${
                    isOpen ? "rotate-45" : ""
                  }`}
                  src={Images.plus}
                  alt={isOpen ? "Collapse" : "Expand"}
                />
              </label>
              <div
                className={`accordion-content onest text-gray-600 overflow-hidden transition-all duration-300 ${
                  isOpen ? "max-h-40 py-4" : "max-h-0 py-0"
                }`}
              >
                <hr className="w-11/12 mx-auto mb-3" />
                <p className="px-4 sm:px-5 text-sm sm:text-base">{data.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FAQs;