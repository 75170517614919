import React, { useState } from 'react';
import { Feature } from '../constants/Feature';
import parseStyles from '../utils/ParsingFunction';

const FeatureItem = () => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="flex flex-col rounded-lg feature text-left grotesk max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Heading */}
      <div className="heading text-left mb-4 sm:mb-6 text-xl sm:text-3xl lg:text-4xl font-medium">
        Features
      </div>
      
      {/* Tab Container */}
      <div className="tab-container bg-white w-full overflow-y-hidden overflow-x-auto sm:overflow-visible">
        <div className="flex tabs flex-row sm:flex-row items-start text-center justify-start sm:justify-center onest text-sm sm:text-base mb-6 sm:mb-11 whitespace-nowrap">
          {Feature.map((feature) => (
            <li
              key={feature.id}
              className={`${
                activeTab === feature.id 
                  ? "active-tab border-b-2 border-black font-medium" 
                  : "cursor-pointer text-gray-500 hover:text-gray-800"
              } flex-shrink-0 min-w-[120px] sm:w-1/4 mb-0 sm:mb-0 px-3 py-2 sm:py-4 transition-colors duration-200`}
              onClick={() => setActiveTab(feature.id)}
            >
              {feature.heading}
            </li>
          ))}
        </div>
      </div>
      
      {/* Feature Content */}
      {Feature.map((data) => {
        if (data.id !== activeTab) return null;
        const styles = parseStyles(data.style);
        return (
          <div 
          className="banner-feature overflow-hidden flex lg:flex-row w-full lg:mt-12"
          key={data.id}
        >
          <div className="left flex flex-col sm:flex-row lg:flex-col w-full sm:w-1/2 sm:mb-0 p-4">
            {/* Heading and Icon */}
            <div className="upper data flex items-center text-left flex-col sm:flex-row">
              <img 
                className="mr-0 sm:mr-4 h-8 w-8 sm:h-8 sm:w-8 mb-3 sm:mb-0" 
                src={data.logo || "/placeholder.svg"} 
                alt={`${data.heading} logo`} 
              />
              <div className="tab-heading text-lg sm:text-xl lg:text-3xl text-black text-center sm:text-left grotesk font-medium">
                {data.heading}
              </div>
            </div>
        
            {/* Description Text */}
            <div className="tab-content text-xs sm:text-sm md:text-base lg:text-xl leading-tight sm:leading-normal onest pl-0 sm:pl-3 mt-3 sm:mt-4 text-gray-700">
              {data.text}
            </div>
          </div>
        
          {/* Image Section */}
          <div className="flex w-full sm:w-1/2 justify-center items-center">
            <img 
              className="why-img max-w-full  h-auto object-contain max-h-[250px] sm:max-h-none" 
              src={data.img || "/placeholder.svg"} 
              alt={`${data.heading} feature`}
              style={styles} 
            />
          </div>
        </div>
        
        );
      })}
    </div>
  );
};

export default FeatureItem;
