import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface UserInfo {
    name: string;
    email: string;
    image: string;
    authSource: string

}

const Dashboard: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const data = localStorage.getItem('user-info');
        if (data) {
            try {
                const userData: UserInfo = JSON.parse(data);
                setUserInfo(userData);
            } catch (error) {
                console.error('Error parsing user-info:', error);
                localStorage.removeItem('user-info');
            }
        }
    }, []);
    
console.log(userInfo?.image)
    const handleLogout = () => {
        localStorage.removeItem('user-info');
        navigate('/login');
    };

    return (
        <>
            {userInfo ? (
                <>
                    <h1>Welcome {userInfo.name}</h1>
                    <h3>{userInfo.email}</h3>
                    {userInfo.image ? (
                        <img className="w-20 h-20" src={userInfo?.image} alt={userInfo.name} />
                    ) : (
                        <p>No image available</p>
                    )}
                    <button onClick={handleLogout}>Logout</button>
                </>
            ) : (
                <h1>Loading...</h1>
            )}
        </>
    );
};

export default Dashboard;
