import React from "react";
import { Images } from "../constants/Images";

const Integration = () => {
  return (
    <div className="integration w-full py-8 sm:py-12 lg:py-16 flex justify-center items-center">
      <img
        className="integration-img w-full max-w-6xl h-auto px-4 sm:px-6 lg:px-8"
        src={Images.bigintegration}
        alt="Integration diagram showing how our system connects various components"
      />
    </div>
  );
};

export default Integration;