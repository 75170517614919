import React, { useEffect, useState } from "react";
import { TrustedBy } from "../constants/Carousel";
import parseStyles from "../utils/ParsingFunction";
import "../utils/style.css";

const Carousel = () => {
  const [contentCopies, setContentCopies] = useState(2);
  const [scrollSpeed, setScrollSpeed] = useState('20s');

  useEffect(() => {
    const updateCarouselConfig = () => {
      const screenWidth = window.innerWidth;
      
      // Adjust number of copies based on screen width
      const newCopies = Math.ceil(screenWidth / (200 * TrustedBy.length)) + 1;
      setContentCopies(newCopies);
      
      // Adjust animation speed based on screen width
      if (screenWidth < 640) { // Mobile
        setScrollSpeed('15s');
      } else if (screenWidth < 1024) { // Tablet
        setScrollSpeed('20s');
      } else { // Desktop
        setScrollSpeed('25s');
      }
    };

    updateCarouselConfig();
    window.addEventListener('resize', updateCarouselConfig);
    
    return () => window.removeEventListener('resize', updateCarouselConfig);
  }, []);

  return (
    <div className="w-full overflow-hidden my-8 md:my-12 lg:my-16 px-2">
      <div className="text-center mb-4 md:mb-6">
        <h3 className="text-lg md:text-xl lg:text-2xl font-medium text-gray-700">Trusted By</h3>
      </div>
      
      <div 
        className="carousel-container relative w-full overflow-hidden"
        style={{ 
          maskImage: 'linear-gradient(to right, transparent, black 10%, black 90%, transparent)',
          WebkitMaskImage: 'linear-gradient(to right, transparent, black 10%, black 90%, transparent)'
        }}
      >
        <div 
          className="carousel-track whitespace-nowrap"
          style={{ 
            animation: `scroll ${scrollSpeed} linear infinite`,
            display: 'inline-block'
          }}
        >
          {Array(contentCopies).fill(TrustedBy).flat().map((data, index) => {
            const styles = parseStyles(data.style);
            
            // Responsive styles
            const responsiveStyles = {
              ...styles,
              // Add any conditional style modifications here if needed
              maxWidth: '100%',
              height: 'auto'
            };
            
            return (
              <div 
                key={index} 
                className="inline-block align-middle mx-4 sm:mx-6 md:mx-8 lg:mx-10 my-2"
              >
                <img
                  className="h-6 sm:h-8 md:h-10 lg:h-12 w-auto object-contain transition-opacity hover:opacity-80"
                  style={responsiveStyles}
                  src={data.img}
                  alt={data.alt || "Trusted partner"}
                  loading="lazy"
                />
              </div>
            );
          })}
        </div>
      </div>
      
      {/* Replace style jsx with regular style element */}
      <style>
        {`
          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-${100 / contentCopies}%);
            }
          }
          
          .carousel-track {
            will-change: transform;
            transform: translateZ(0);
          }
        `}
      </style>
    </div>
  );
};

export default Carousel;