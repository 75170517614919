import { useState } from "react"

export default function useNavigation () {
    const [navIndex, setNavIndex] = useState(0)

    const navHandler = (direction:any) => {
        if(direction === "continue"){
            setNavIndex(currentIndex => currentIndex + 1)
        }
    }
    return { navIndex, navHandler}
}
