import React from "react";
import { Images } from "../constants/Images";
import { footer } from "../constants/Footer";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const handleClick = (route: string) => {
    navigate(route);
  };

  return (
    <footer className="footer w-full bg-[#00264c] text-white py-10 sm:py-12 lg:py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row justify-between gap-8 lg:gap-12">
          {/* Left Section */}
          <div className="footer-l text-left lg:w-1/3">
            <div className="f-logo flex items-center mb-4 sm:mb-6">
              <img
                className="w-8 h-8 sm:w-10 sm:h-10 mr-3"
                src={Images.footerLogo}
                alt="Zintellix Logo"
              />
              <div className="text-xl sm:text-2xl lg:text-3xl font-bold">
                Zintellix
              </div>
            </div>
            <p className="text-sm sm:text-base max-w-xs mb-6 sm:mb-8 text-gray-300">
              AI Data visualization, and expense management for your products.
            </p>
            <div className="f-logos flex space-x-4 sm:space-x-6">
              <a
                href="https://www.linkedin.com/company/zintellix/posts/?feedView=all"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visit our LinkedIn"
              >
                <img
                  className="w-6 h-6 sm:w-8 sm:h-8 hover:opacity-80 transition-opacity"
                  src={Images.linkedin}
                  alt="LinkedIn"
                />
              </a>
            </div>
          </div>

          {/* Right Section */}
          <div className="footer-r w-full lg:w-2/3">
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-6 sm:gap-8 lg:gap-10">
              {footer.map((item) => (
                <ul key={item.id} className="text-left">
                  <li className="font-semibold text-lg sm:text-xl lg:text-2xl mb-3 sm:mb-4">
                    {item.text}
                  </li>
                  {item.subItems.map((subItem, index) => (
                    <li
                      key={index}
                      className="text-sm sm:text-base text-gray-300 hover:text-white cursor-pointer mb-2 transition-colors"
                      onClick={() => handleClick(item.redirect[index])}
                    >
                      {subItem}
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-10 sm:mt-12 lg:mt-16">
          <hr className="border-gray-600 mb-6 sm:mb-8" />
          <div className="flex flex-col sm:flex-row items-center justify-between text-gray-400 text-xs sm:text-sm gap-4 sm:gap-0">
            <div className="flex flex-wrap justify-center sm:justify-start gap-3 sm:gap-4">
              <span
                className="cursor-pointer hover:text-white transition-colors"
                onClick={() => handleClick("/privacy")}
              >
                Privacy Policy
              </span>
              <span className="hidden sm:inline text-gray-600">|</span>
              <span
                className="cursor-pointer hover:text-white transition-colors"
                onClick={() => handleClick("/tnc")}
              >
                Terms & Conditions
              </span>
              <span className="hidden sm:inline text-gray-600">|</span>
              <span
                className="cursor-pointer hover:text-white transition-colors"
                onClick={() => handleClick("/cookie")}
              >
                Cookie Policy
              </span>
            </div>
            <div className="text-center sm:text-left">
              <span>Made with ❤️ in Rajasthan</span>
            </div>
            <div>
              <span className="cursor-pointer hover:text-white transition-colors">
                © Zintellix 2025
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
