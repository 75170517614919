import KeshavSharma from "../assets/images/testimonial pic/KeshavSharma.jpg";
import SaurabhSharma from "../assets/images/testimonial pic/SaurabhSharma.jpg";
import VidushiMittal from "../assets/images/testimonial pic/Vidushi Mittal.jpg";

export const testimonials = [
  {
    id: 1,
    quote:
      "Before Zintellix, our data was scattered and hard to interpret. Now, everything is centralized, visualized, and easy to act on. We've seen a 30% increase in conversion rates thanks to the insights provided.",
    name: "Keshav Sharma",
    designation: "CEO (Churan Chacha)",
    src: KeshavSharma,
  },
  {
    id: 2,
    quote:
      "Zintellix embodies innovation. Its predictive analytics and deep customer insights have helped us anticipate trends and stay ahead of the competition. This platform is the future of e-commerce analytics.",
    name: "Saurabh Sharma",
    designation: "CEO (Electroglobal)",
    src: SaurabhSharma,
  },
  {
    id: 3,
    quote:
      "Zintellix made analytics accessible to our entire team. From marketing to operations, every department now has the insights they need to make informed decisions. It’s saved us time, money, and effort while driving measurable results.",
    name: "Vidushi Mittal",
    designation: "CEO (Kalaai Studio)",
    src: VidushiMittal,
  },
];
