import Form from "../components/Form";
import { Feature } from "../constants/Feature";
import "../utils/style.css";
import Testinomials from "../components/Testinomials";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel";
import Header from "../components/Header";

const DemoPage = () => {
  return (
    <div>
      <Header backgroundStyle="" />
      <div className="flex flex-col lg:h-[95vh] h-[130vh] md:flex-row text-left justify-around mt-10 p-4 md:p-10 demoPage mb-16 md:mb-20 lg:mb-24">
        <div className="w-full md:w-1/2 md:mb-0 md:ml-6">
          <div className="text-3xl sm:text-4xl md:text-5xl font-normal">
            Book <span className="us">free</span> demo now
          </div>
          <div className="text-sm sm:text-base font-normal onest mt-4 md:mt-6 mb-8 md:mb-12 w-full md:w-3/4">
            Tell us a little about yourself, and we'll connect you with a
            superchat expert who can tell you more about the product and answer
            your questions.
          </div>
          <div className="features">
            <div className="text-sm sm:text-base font-normal onest mt-4 md:mt-6 mb-6 md:mb-8">
              Our features :
            </div>
            {Feature.map((data) => (
              <div key={data.heading} className="flex mt-3 md:mt-4">
                <img
                  className="mr-3 md:mr-5 h-5 w-5 md:h-6 md:w-6"
                  src={data.logo}
                  alt=""
                />
                <div className="font-normal text-lg md:text-xl onest">
                  {data.heading}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full h-full mt-4 md:w-1/2 right">
          <Form />
        </div>
      </div>

      <Carousel />
      <Testinomials />
      <Footer />
    </div>
  );
};

export default DemoPage;
