import React, { useState } from "react";
import { reset } from "../constants/Reset";
import { toast } from "react-toastify";

const PasswordReset: React.FC = () => {
  const [credentials, setCredentials] = useState({ email: "" });

  // Handle input change
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  // Form submission handler
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!credentials.email) {
      toast.error("Email cannot be empty.");
      return;
    }

    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(credentials.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/forget`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: credentials.email }),
      });

      const json = await response.json();

      if (json.success) {
        toast.success("Check your email inbox for further instructions.");
      } else {
        toast.error(json.message || "Failed to process the request.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const { email } = reset;

  return (
    <div className="bg-1 absolute w-full h-screen flex items-center justify-center">
      <div className="bg-white w-1/3 sm:p-8 p-6 shadow-md rounded-lg">
        <div className="space-y-6">
          {/* Logo */}
          <div className="w-11/12 mx-auto">
            <img className="h-10 w-[150px]" src={email.logo} alt="Logo" />
          </div>

          {/* Title and Content */}
          <div className="my-5 w-11/12 px-4">
            <h3 className="text-xl grotesk font-medium">{email.title}</h3>
            <p className="text-sm text-gray-600 leading-6">{email.content}</p>
          </div>

          {/* Form */}
          <form className="w-11/12 mx-auto" onSubmit={handleSubmit}>
            {/* Email Input */}
            <div className="mb-6">
              <label className="block text-sm font-medium mb-2">{email.label}</label>
              <input
                name="email"
                type="email"
                value={credentials.email}
                onChange={onChange}
                required
                className="w-full border border-gray-300 rounded-md p-3 text-sm focus:outline-blue-500"
                placeholder={email.placeholder}
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 rounded-md font-medium hover:bg-blue-700 transition"
            >
              {email.button}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
