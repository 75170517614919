import React from 'react';
import { ArrowLeft, BarChart2, TrendingUp } from 'lucide-react';

const NotFoundPage: React.FC = () => {
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-300 flex items-center justify-center p-6">
      <div className="relative max-w-lg w-full bg-white rounded-3xl shadow-2xl p-10 text-center space-y-8 border border-gray-200">
        {/* Floating Icon */}
        <div className="absolute -top-12 left-1/2 transform -translate-x-1/2">
          <div className="bg-gradient-to-br from-blue-500 to-indigo-500 text-white rounded-full p-5 shadow-xl">
            <BarChart2 size={56} className="animate-bounce" />
          </div>
        </div>

        {/* Content */}
        <div className="mt-10">
          <h1 className="text-7xl font-extrabold text-gray-800">404</h1>
          <p className="text-lg font-medium text-gray-500 mt-4">
            Oops! This page isn't on the chart.
          </p>
        </div>

        {/* Description */}
        <p className="text-gray-600 leading-relaxed">
          It looks like the dashboard or page you’re looking for doesn’t exist yet. Let’s get you back on track!
        </p>

        {/* Action Buttons */}
        <div className="flex items-center justify-center space-x-4">
          <TrendingUp className="text-indigo-400 animate-pulse" size={28} />
          <div className="h-10 w-px bg-gray-300"></div>
          <button
            onClick={handleGoBack}
            className="flex items-center space-x-2 bg-gradient-to-r from-indigo-500 to-blue-500 text-white font-semibold px-5 py-3 rounded-full shadow-md hover:shadow-lg hover:scale-105 transition-all duration-300 ease-in-out"
          >
            <ArrowLeft size={20} />
            <span>Go Back</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
