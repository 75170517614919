import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Images } from "../constants/Images";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { googleAuth } from "../utils/api";

interface Credentials {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const Registration: React.FC = () => {
  const [credentials, setCredentials] = useState<Credentials>({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  // Google OAuth Login Handler
  const responseGoogle = async (authResult: any) => {
    try {
      if (authResult.code) {
        const result = await googleAuth(authResult.code);
        const { email, name, image, _id } = result.data.user;
        const token = result.data.token;

        const userInfo = { email, name, token, image, _id };
        localStorage.setItem("user-info", JSON.stringify(userInfo));

        toast.success("Google login successful!");
        navigate("/upgrade-plan");
      } else {
        throw new Error("Google login failed.");
      }
    } catch (error) {
      console.error("Error while Google Login:", error);
      toast.error("Google login failed. Please try again.");
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: () => toast.error("Google login failed"),
    flow: "auth-code",
  });

  // Handle Form Submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { name, email, password, confirmPassword } = credentials;
  
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
  
    try {
      console.log("Sending request with payload:", { name, email, password, confirmPassword });

      console.log("Sending request to backend...");
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/signup`,
        { name, email, password, confirmPassword }
      );
      if (response.data.success) {
        localStorage.setItem("authToken", response.data.token);
        toast.success("Account created successfully!");
        navigate("/login");
      } else {
        toast.error(response.data.error || "Registration failed.");
      }
    } catch (error: any) {
      console.error("Registration error:", error);
      toast.error(error.response?.data?.error || "An unexpected error occurred.");
    }
  };
  

  // Handle Input Changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="bg-1 absolute w-screen h-screen flex justify-center items-center">
      <div className="bg-white max-w-md sm:w-1/3 w-11/12 p-6 shadow-lg rounded-xl mx-auto">
        <div className="w-full flex justify-center">
          <img className="h-10" src={Images.zintellixLogo} alt="Logo" />
        </div>

        <div className="my-4 text-left">
          <h3 className="text-xl font-medium">Sign Up</h3>
          <p className="text-[#52606D] leading-8">Create your account on Zintellix</p>
        </div>

        <div className="flex items-center my-4">
          <button
            onClick={googleLogin}
            className="w-full flex items-center justify-center border-2 border-slate-700 py-2 rounded-full cursor-pointer hover:bg-gray-100 transition"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1024px-Google_%22G%22_logo.svg.png"
              className="w-6 h-6 mr-3"
              alt="Google Logo"
            />
            Continue with Google
          </button>
        </div>

        <div className="flex items-center my-2">
          <hr className="w-1/2" />
          <p className="text-center px-2">OR</p>
          <hr className="w-1/2" />
        </div>

        <form className="w-full" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label className="text-sm font-semibold">Name</label>
              <input
                name="name"
                type="text"
                required
                value={credentials.name}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your name"
              />
            </div>

            <div>
              <label className="text-sm font-semibold">Email</label>
              <input
                name="email"
                type="email"
                required
                value={credentials.email}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your email"
              />
            </div>

            <div>
              <label className="text-sm font-semibold">Password</label>
              <input
                name="password"
                type="password"
                required
                value={credentials.password}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your password"
              />
            </div>

            <div>
              <label className="text-sm font-semibold">Confirm Password</label>
              <input
                name="confirmPassword"
                type="password"
                required
                value={credentials.confirmPassword}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-blue-500"
                placeholder="Confirm your password"
              />
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-md mt-4 hover:bg-blue-600 transition"
          >
            Sign Up
          </button>
        </form>

        <p className="text-center text-sm mt-4">
          Already have an account?{" "}
          <a href="/login" className="text-blue-600 hover:underline">
            Log in
          </a>
        </p>
      </div>
    </div>
  );
};

export default Registration;
