import React, { useState } from "react";
import { testimonials } from "../constants/Testinomial";
import { Images } from "../constants/Images";
import "../utils/style.css";

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  const handlePrevClick = () => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
      );
      setIsFading(false);
    }, 300);
  };

  const handleNextClick = () => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
      setIsFading(false);
    }, 300);
  };

  const data = testimonials[currentIndex];

  return (
    <div className=" w-full min-h-[35vh]">
      {" "}
      {/* Removed sm:w-1/3 and added w-full */}
      <div
        className={`testimonial-card ${
          isFading ? "fade-out" : "fade-in"
        }`}
      >
       <div
  className="card flex relative bg-white text-[#52606D] outline-blue-500 p-4 sm:p-6 
  w-[85vw] max-w-[50vh] h-auto min-h-[250px] md:min-h-[150px] lg:h-[350px] lg:overflow-auto rounded-lg shadow-md"
>
  <div>
    <img
      src={Images.quotes}
      className="absolute left-4 top-4 w-5 h-5 sm:w-6 sm:h-6"
      alt="Quote"
    />
  </div>
  <div className="text absolute top-12 sm:top-14 left-5 right-5 text-sm sm:text-base md:text-lg">
    {data.quote}
  </div>
</div>

        <div className="flex mt-8 sm:mt-16 justify-between">
          <div className="flex">
            <div className="img">
              <img
                src={data.src}
                alt=""
                className="person-img w-12 h-12 sm:w-16 sm:h-16"
              />
            </div>
            <div className="details text-start ml-4 sm:ml-6 self-center">
              <div className="name text-sm sm:text-base">{data.name}</div>
              <div className="desig text-xs sm:text-sm">{data.designation}</div>
            </div>
          </div>
          <div className="arrow flex items-center mr-2 sm:mr-5">
            <img
              src={Images.left}
              alt="Previous"
              className="w-6 h-6 sm:w-8 sm:h-8 cursor-pointer bg-white m-1 sm:m-2"
              onClick={handlePrevClick}
            />
            <img
              src={Images.right}
              alt="Next"
              className="w-6 h-6 sm:w-8 sm:h-8 cursor-pointer bg-white m-1 sm:m-2"
              onClick={handleNextClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Reviews;
