import React from "react";

// Define the type for the feature prop
interface Feature {
  featureType: string;
  headings: string[];
  texts: string[];
  logos: string[];
}

const FeatureComponent = ({ feature }: { feature: Feature }) => {
  return (
    <div className="space-y-6">
      {/* Feature Type Badge */}
      <div className="text-center 
        bg-white 
        text-[#2F269D] 
        py-2 px-4 
        inline-block 
        text-sm sm:text-base">
        {feature.featureType}
      </div>

      {/* Features List */}
      {feature.headings.map((heading: string, index: number) => (
        <div key={index} className="flex items-start space-x-4">
          {/* Logo Circle */}
          <div className="flex-shrink-0 
            w-10 h-10 
            rounded-full 
            bg-white 
            border-4 
            border-[#8BBCF2] 
            flex items-center justify-center">
            <img
              src={feature.logos[index]}
              alt={`${heading} logo`}
              className="w-5 h-5 object-contain"
            />
          </div>

          {/* Feature Details */}
          <div>
            <h3 className="text-base sm:text-lg font-semibold text-gray-800 mb-2">
              {heading}
            </h3>
            <p className="text-sm text-gray-600 leading-relaxed">
              {feature.texts[index]}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureComponent;