import { useCallback } from 'react';
import { User } from '../types/user';

export const useAuth = () => {
  const login = useCallback(async (user: User) => {
    try {
      localStorage.setItem('user', JSON.stringify(user));
      return true;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  }, []);

  const logout = useCallback(() => {
    try {
      localStorage.removeItem('user');
      localStorage.removeItem('authToken');
      return true;
    } catch (error) {
      console.error('Logout error:', error);
      return false;
    }
  }, []);

  const getStoredUser = useCallback((): User | null => {
    try {
      const storedUser = localStorage.getItem('user');
      return storedUser ? JSON.parse(storedUser) : null;
    } catch (error) {
      console.error('Error getting stored user:', error);
      return null;
    }
  }, []);

  return { login, logout, getStoredUser };
};